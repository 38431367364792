<template>
  <div class="news-wrap">
    <search-news v-if="getSearchCondition"></search-news>
    <div class="main-wrap" style="padding: 10px">
      <div class="bar-wrap">
        <!-- <el-input
          v-model="search_text"
          class="search-bar"
          placeholder="請輸入活動名稱"
          :prefix-icon="Search"
          clearable
          @keyup.enter="handleSearch"
          @clear="handleSearch"
        /> -->
        <el-button class="create-button" type="primary" @click="openDialog('newsSetting', { action: 'create' })">
          新增
        </el-button>
      </div>
      <el-table
        ref="listTable"
        :data="getNewsList"
        header-cell-class-name="cus-table-header"
        row-class-name="cus-table-row"
        empty-text="尚無資料"
        class="table-basic"
        height="100%"
        @expand-change="handleRowClick"
      >
        <el-table-column type="expand">
          <template #default="props">
            <div class="remark">
              <p v-html="'活動說明：' + props.row.memo.replace(/\n/g, '<br>')"></p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="event_id" label="活動ID" width="120" />
        <el-table-column prop="event_name" label="活動名稱" min-width="160" :show-overflow-tooltip="true" />
        <el-table-column prop="media_url" label="內容" width="160">
          <template #default="scope">
            <div v-if="scope.row.media_url && scope.row.media_url != ''">
              <img class="media" :src="scope.row.media_url" alt="" />
            </div>
            <el-tooltip
              v-else
              popper-class="content_message_tooltip"
              :content="tooltip_content(scope.row.content[0]?.message)"
              placement="right"
              raw-content
            >
              <div class="content_message">{{ scope.row.content[0]?.message }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="store_list_type" label="門市" width="200">
          <template #default="scope">
            <span v-if="scope.row.store_list_type === 1">全門市</span>
            <span v-else-if="scope.row.store_list_type === 2">
              <!-- {{ getAreaText(scope.row.available_shop_value_array) }} -->
              {{ getAreaText(scope.row.store_area) }}
            </span>
            <span
              v-else-if="scope.row.store_list_type === 3"
              class="download-text"
              @click="downloadFile(scope.row.store_file_url)"
            >
              指定門市（csv）
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="start_date" label="活動日期" width="170">
          <template #default="scope">
            <span v-if="scope.row.date_type === 1">不指定時間</span>
            <span v-if="scope.row.date_type === 2">
              {{ formatDateTime('YYYY/MM/DD HH:mm', scope.row.start_date) }}~
              <br />
              {{ formatDateTime('YYYY/MM/DD HH:mm', scope.row.end_date) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="available_status" label="狀態" width="110" />
        <el-table-column prop="print_time" label="列印時機" width="130">
          <template #default="scope">
            <span v-if="scope.row.print_time === 1">訂單結帳後</span>
            <span v-else-if="scope.row.print_time === 2">滿額</span>
            <span v-else-if="scope.row.print_time === 3">旺卡加值</span>
          </template>
        </el-table-column>
        <el-table-column prop="creator" label="新增者-建立時間" width="220">
          <template #default="scope">
            <span>{{ scope.row.creator }}-{{ formatDateTime('YYYY/MM/DD HH:mm', scope.row.create_time) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="last_update_user" label="修改者-修改時間" width="220">
          <template #default="scope">
            <span>
              {{ scope.row.last_update_user }}-{{ formatDateTime('YYYY/MM/DD HH:mm', scope.row.last_update_time) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="功能" fixed="right" align="center" width="130">
          <template #default="scope">
            <el-dropdown class="action">
              <span class="el-dropdown-link">
                操作
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    @click="openDialog('newsSetting', { action: 'edit', init: scope.row, index: scope.$index })"
                  >
                    編輯
                  </el-dropdown-item>
                  <el-dropdown-item @click="handleDeleteNews(scope.row)">刪除</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        v-model:currentPage="currentPage"
        :page-size="getPageSize"
        layout="prev, pager, next, jumper"
        :total="getPageTotal"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
  <!-- 彈窗 -->
  <component :is="dialogTemplate"></component>
</template>
<script>
import { getCurrentInstance, defineAsyncComponent, ref, watch } from 'vue';
import { useDialog } from '@/utils/dialog';
import { useRoute, useRouter } from 'vue-router';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
import { formatDateTime, getFormatDate } from '@/utils/datetime';
import { downloadFile } from '@/utils/download';
import { getAreaText } from '@/utils/define';
import { Search } from '@element-plus/icons-vue';
export default {
  name: 'news',
  components: {
    NewsDialog: defineAsyncComponent(() => import('@/components/information/NewsDialog.vue')),
    SearchNews: defineAsyncComponent(() => import('@/components/information/SearchNews.vue')),
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const { dialogTemplate, openDialog, closeDialog } = useDialog();
    const route = useRoute();
    const router = useRouter();

    //設定參數取得資料
    const search_text = ref('');
    const listTable = ref(null);
    const currentPage = ref(1);
    const { getNewsList, getPageSize, getPageTotal, getSearchCondition } = useGetters('news', [
      'getNewsList',
      'getPageSize',
      'getPageTotal',
      'getSearchCondition',
    ]);
    const { doGetNewsList, doGetNewsSummary } = useActions('news', ['doGetNewsList', 'doGetNewsSummary']);
    const SET_SEARCH_CONDITION = useMutations('news', ['SET_SEARCH_CONDITION']);

    //初始化
    const query = { page: 1 };
    const searchDataInit = {
      date: [getFormatDate(), getFormatDate()],
      event_id: '',
      event_name: '',
      available_status: '',
    };
    router.push({ query, replace: true }).then(() => {
      currentPage.value = Number(route.query.page);
      SET_SEARCH_CONDITION(searchDataInit);
      if (route.query.page && getSearchCondition.value) {
        refreshList('init');
      }
    });

    //更新資料
    const refreshList = async (action) => {
      if (action === 'create') {
        currentPage.value = 1;
        const query = { page: currentPage.value };
        router.push({ query });
        search_text.value = '';
        SET_SEARCH_CONDITION(searchDataInit);
      }
      if (action !== 'edit' && action !== 'page') await doGetNewsSummary(getSearchCondition.value);
      if (currentPage.value > Math.ceil(getPageTotal.value / getPageSize.value))
        currentPage.value = Math.max(Math.ceil(getPageTotal.value / getPageSize.value), 1);
      await doGetNewsList({ ...getSearchCondition.value, start: (currentPage.value - 1) * getPageSize.value });
      closeDialog();
    };
    const tooltip_content = (msg) => {
      let newMsg;
      if (msg != undefined) {
        if (msg.length > 100) {
          msg = msg.slice(0, 100);
          newMsg = msg.replace(/\n/g, '<br>') + '...';
          return newMsg;
        } else {
          newMsg = msg.replace(/\n/g, '<br>');
          return newMsg;
        }
      }
    };

    //點擊分頁
    const handleCurrentChange = () => {
      const query = { page: currentPage.value };
      router.push({ query });
    };

    // //搜尋
    // const handleSearch = () => {
    //   refreshList('search');
    // };

    //更新list
    watch(
      () => route.query.page,
      async () => {
        if (route.query.page && getSearchCondition.value) {
          currentPage.value = Number(route.query.page);
          refreshList('page');
          listTable.value.scrollBarRef.scrollTo(0, 0);
        }
      }
    );

    //刪除訊息
    const doDeleteNews = useActions('news', ['doDeleteNews']);
    const handleDeleteNews = (item) => {
      proxy
        .$confirm(`確定要刪除－${item.event_name}？`, '確認刪除', {
          confirmButtonText: '刪除',
          cancelButtonText: '取消',
          type: 'warning',
          confirmButtonClass: 'el-button--danger',
        })
        .then(async () => {
          await doDeleteNews(item.event_id);
          refreshList('delete');
          proxy.$message({
            type: 'success',
            message: '刪除成功',
          });
        });
    };

    //展開收合row
    const handleRowClick = (row, expandedRows) => {
      if (expandedRows.includes(row)) {
        expandedRows.forEach((e) => {
          if (e !== row) listTable.value.toggleRowExpansion(e, false);
        });
      }
    };

    //離開清空vuex
    const RESET = useMutations('news', ['RESET']);
    watch(
      () => route.path,
      () => {
        if (!route.path.includes('/information/news')) RESET();
      }
    );

    return {
      Search,
      getAreaText,
      formatDateTime,
      tooltip_content,
      downloadFile,
      dialogTemplate,
      openDialog,
      search_text,
      listTable,
      currentPage,
      getPageSize,
      getPageTotal,
      getNewsList,
      handleCurrentChange,
      // handleSearch,
      refreshList,
      handleDeleteNews,
      handleRowClick,
      getSearchCondition,
    };
  },
};
</script>
<style lang="scss">
//  須在全域，因為不在同組件，名稱(.content_message_tooltip)請勿重複
.content_message_tooltip {
  max-width: 250px;
}
</style>
<style lang="scss" scoped>
$padding-unit: 10px;
$row-height: 55px;
$searchbar-height: 70px;
$pagination-height: 50px;
.news-wrap {
  height: 100%;
  @include basic-search-condition;
}
.main-wrap {
  width: 100%;
  height: 100%;
  padding: $searchbar-height $padding-unit $pagination-height !important;
  position: relative;
  overflow: scroll;
  @include white-bg;
}

.bar-wrap {
  position: absolute;
  display: flex;
  top: calc($searchbar-height / 2);
  right: $padding-unit;
  transform: translateY(-50%);
}
.create-button {
  width: 110px;
  margin-left: 20px;
}
.search-bar {
  width: 200px;
}
.table-basic {
  width: 100%;
  border-top: 1px solid $el-border-color-lighter;
}
.action {
  cursor: pointer;
  width: 110px;
  @include flex-center-center;
}
.el-dropdown-link {
  color: $color-primary;
}
.pagination {
  position: absolute;
  left: 50%;
  bottom: calc($pagination-height / 2);
  transform: translate(-50%, 50%);
}
.remark {
  padding: 10px 60px;
  color: $color-text-light;
  font-size: 1.5rem;
}
.media {
  display: block;
  width: 100px;
  height: 80px;
  object-fit: contain;
}
.content_message {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
